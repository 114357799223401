import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { Container } from "reactstrap";
import { Section, PageHeroSection } from "components/section";
import Layout from "components/Layout";
import styled from "styled-components";

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  .gatsby-image-wrapper {
    flex: 1 1 40%;
    margin: 10px;
  }
`;

const Gallery = ({ data }) => {
  let images = data.allFile.edges;
  return (
    <Layout>
      <Helmet>
        <title>Gallery - CleanWright</title>
      </Helmet>
      <PageHeroSection>
        <h1>Previous Job Examples</h1>
      </PageHeroSection>
      <Section>
        <Container>
          <GalleryWrapper>
            {images.map(({ node }) => (
              <Img
                alt="previous work"
                fluid={node.childImageSharp.fluid}
                key={node.id}
              />
            ))}
          </GalleryWrapper>
          <h3>Think your home could do with a little TLC?</h3>
          <p>
            If you’d like to enjoy some of the benefits of having clean and
            fresh carpets, upholstery or and curtains, why not
            <Link to="/quote/">request a free quote</Link>?
          </p>
        </Container>
      </Section>
    </Layout>
  );
};

export default Gallery;

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { glob: "gallery/*" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
